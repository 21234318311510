<template>
  <div class="table-responsive relative">
    <div style="min-width:350px">
      <table class="relative table table-hover table-bordered text-center align-middle mb-0">
        <thead>
          <tr class="border-0 bg-transparent">
            <th class="w-1/4 border-0 px-2" style="background: transparent"></th>
            <th class="w-1/4 border-0 p-0" style="background: transparent">
              <h3
                class="bg-blue-50 border rounded-t-2xl p-1 sm:px-2 sm:py-3 mb-0 mx-0.5 h-16 md:h-24 flex items-center justify-center"
              >
                <span class="block nunitoBold font-bold text-pri-900">Quy mô</span>
              </h3>
            </th>
            <th class="w-1/4 border-0 p-0" style="background: transparent;">
              <h3
                class="bg-blue-50 border rounded-t-2xl p-1 sm:px-2 sm:py-3 mb-0 mx-0.5 h-16 md:h-24 flex items-center justify-center flex-col"
              >
                <span class="nunitoBold text-pri-900">Chi phí</span>
                <span class="nunitoBold-sm text-pri-900">/Gói/Năm</span>
              </h3>
            </th>
            <th class="w-1/4 border-0 px-2" style="background: transparent"></th>
          </tr>
        </thead>
      </table>
      <table
        v-for="(item, index) in HODO_RETAIL_PRICE"
        :key="index"
        class="relative table table-hover table-bordered text-center align-middle mb-0"
      >
        <thead>
          <tr
            class="cursor-pointer relative z-10"
            :class="{
                  'bg-blue-50': activeIndex === index
                }"
            @click.stop="showDetails(item, index)"
          >
            <th :colspan="item.benefit?3:4" class="text-left">
              <span
                class="inline-block"
                :style="{
                      transform: activeIndex === index ? `rotate(0)` : `rotate(-90deg)`
                    }"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M7 9.5L12 14.5L17 9.5H7Z" fill="#1D2939" />
                </svg>
              </span>
              <span v-html="item.name" style="font-weight:600" class="uppercase text-xl"></span>
            </th>
            <th class="w-1/4" v-if="item.benefit">
              <strong class="uppercase text-2xl">{{item.benefit}}</strong>
            </th>

            <!-- <td v-html="formatterData(item.standard)"></td>
            <td v-html="formatterData(item.plus)"></td>
            <td v-html="formatterData(item.pro)"></td>-->
          </tr>
          <template>
            <tr
              class="bg-white relative z-20"
              v-if="activeIndex === index && hodoServiceDetail && hodoServiceDetail.description.length"
              :key="`detail-${index}`"
            >
              <td colspan="4" class="text-left">
                <ul class="list-disc list-inside mb-0">
                  <li
                    class="text-xl"
                    v-for="(item, index) of hodoServiceDetail.description"
                    :key="index"
                    v-html="item.label"
                  ></li>
                </ul>
              </td>
            </tr>
          </template>
        </thead>
        <tbody v-if="item.packages && item.packages.length">
          <template v-for="(packageInfo,packageIndex) in item.packages">
            <tr :key="packageIndex">
              <td class="w-1/4 text-left">{{packageInfo.name}}</td>
              <td class="w-1/4 text-center">
                <div
                  class="flex flex-col sm:flex-row sm:gap-2 sm:justify-center"
                  v-html="packageInfo.scale"
                ></div>
              </td>
              <td class="w-1/4">{{packageInfo.price}}</td>
              <td class="w-1/4">{{packageInfo.benefit}}</td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { HODO_RETAIL_PRICE } from '../../utils/constant'

export default {
  name: 'RetailPrice',
  data () {
    return {
      HODO_RETAIL_PRICE,
      activeIndex: null,
      hodoServiceDetail: null
    }
  },

  methods: {
    showDetails (item, index) {
      if (this.activeIndex === index) {
        this.onHide()
        return
      }
      this.activeIndex = index
      this.hodoServiceDetail = { ...item }
    },
    onHide () {
      this.hodoServiceDetail = null
      this.activeIndex = null
    }
  }
}
</script>

<style lang="scss" scoped>
@font-face {
  font-family: Nunito;
  src: url("../../../public/font/NunitoSans-ExtraBold.ttf");
  font-weight: 700;
}

.nunitoBold {
  font-family: Nunito !important;
  font-weight: 700 !important;
}

.nunitoBold-sm {
  font-family: Nunito !important;
  font-weight: 700 !important;
  font-size: 18px;
}
</style>
