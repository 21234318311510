<template>
  <div class="container txt-logo features-list text-2xl mb-32">
    <div class="py-3 sm:py-5">
      <h2 class="text-center flex justify-center md:gap-3 flex-col md:flex-row">
        <div
          class="font-bold nunitoBold text-4xl uppercase text-blue-900"
        >{{ $t('Chính sách giá') }}</div>
        <div class="font-bold nunitoBold text-4xl uppercase text-blue-900">HODO MEET</div>
      </h2>
    </div>
    <RetailPrice />
  </div>
</template>

<script>
import RetailPrice from './RetailPrice.vue'

export default {
  name: 'MeetPricing',
  components: { RetailPrice }
}
</script>

<style lang="css" scoped>
@font-face {
  font-family: Montserrat-SemiBold;
  src: url("../../../public/font/Montserrat-SemiBold.ttf");
  font-weight: 600;
}
@font-face {
  font-family: Nunito;
  src: url("../../../public/font/Nunito-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: Nunito;
  src: url("../../../public/font/NunitoSans-ExtraBold.ttf");
  font-weight: 700;
}

.txt-logo {
  color: #20409b;
}
.features-list /deep/ *,
.features-list /deep/ ul,
.features-list /deep/ ul li {
  font-family: Nunito !important;
  font-weight: 400;
}
.features-list /deep/ strong {
  font-family: Nunito !important;
  font-weight: 700;
}
.features-list /deep/ .el-collapse-item__header {
  height: auto !important;
  line-height: auto !important;
}
.features-list /deep/ .el-collapse-item__content {
  padding-bottom: 0 !important;
}
.features-list /deep/ .el-collapse-item__arrow {
  display: none !important;
}
.features-list /deep/ table thead tr {
  background-color: #f1f2f2;
}
/* .features-list /deep/ table {
  border: 1px solid #dee2e6;
}
.features-list /deep/ table th,
.features-list /deep/ table td {
  border-bottom: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  padding: 4px 8px;
} */
.features-list /deep/ table th {
  font-family: Nunito !important;
  font-weight: 700;
}
.nunitoRegular {
  font-family: Nunito !important;
  font-weight: 400;
}
.nunitoBold {
  font-family: Nunito !important;
  font-weight: 700;
}
.text-none {
  font-size: 0 !important;
}

@media (max-width: 740px) {
  .features-list /deep/ * {
    font-size: 14px;
    line-height: 1.5;
  }
  .features-list h2 * {
    font-size: 32px;
  }
  .features-list /deep/ table td {
    padding: 4px;
  }
  .features-list /deep/ table {
    font-size: 13px;
  }
}
</style>
